import React from 'react';
import logo from './logo.svg';
import './App.css';
// import {JoinPage} from "./pages/join";
import {HomePage} from "./pages/Home";
import {ErrorPage} from "./pages/Error";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {TermsPage} from "./pages/Terms";
import '@aws-amplify/ui-react/styles.css';

function App() {
  return (
      <>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            {/*<Route path="/join" element={<JoinPage/>}/>*/}
            <Route path="/terms" element={<TermsPage/>}/>
            <Route path="/privacy" element={<ErrorPage/>}/>
            <Route path="/help" element={<ErrorPage/>}/>
            <Route path="/error" element={<ErrorPage/>}/>
            <Route path="/*" element={<ErrorPage/>}/>
          </Routes>
        </Router>
      </>
  );
}

export default App;
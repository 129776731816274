import React from "react";
import {
    Button,
    Card, Collection,
    Divider,
    Flex, Icon, Link,
    Loader,
    Text,
    ToggleButton,
    ToggleButtonGroup,
    useAuthenticator,
    View, VisuallyHidden
} from "@aws-amplify/ui-react";
import {Helmet} from "react-helmet-async";
import {Footer} from "../components/Footer";
import {Header} from "../components/Header";

export const TermsPage: React.FC = () => {


    return (
        <Flex direction="column" minHeight="calc(100vh)"
              gap="0rem" backgroundColor="#F3F4F4"
        >
            <Header />

            <Helmet>
                <meta charSet="utf-8"/>
                <title>"Darkscreen Terms of Service"</title>
                {/*<link rel="canonical" href="http://mysite.com/example" />*/}
            </Helmet>

            <Flex direction="column" justifyContent="flex-start"
                  alignItems="flex-start" alignContent="flex-start"
                  gap="0rem">
                <Flex direction="row" gap="0rem" width="100%" minHeight="calc(100vh - 165px)"
                      justifyContent="center" alignContent="center"
                      alignItems="flex-start"
                >
                    <Flex direction="column" gap="0rem" width="100%" height="100%"
                          justifyContent="flex-start" alignContent="center"
                          alignItems="center"
                          padding="1rem"
                    >

                        <Flex
                            direction="column" gap="1rem" width="100%" height="100%"
                            justifyContent="flex-start" alignContent="center"
                            alignItems="center" backgroundColor="white" borderRadius="20px" padding="1rem"
                        >
                            <Text
                                color="black"
                                fontWeight={700}
                            >
                                Darkscreen™ Terms of Service
                            </Text>

                            <Flex
                                direction="column"
                                width="100%"
                                justifyContent="flex-start"
                                alignContent="center"


                            >

                                <Text

                                >
                                    Effective Date: November 30, 2023
                                </Text>

                                <Text

                                >
                                    Darkscreen Annotate is a platform for software to streamline business, including web and mobile apps (“App”), provided and operated by Darkscreen Inc., a Deleware C Corp, together with its affiliates, representatives, consultants, employees, officers, and directors (collectively, “Darkscreen,” “we,” “us,” and/or “our”) through the Darkscreen platform and websites (including https://darkscreen.co/) (such platform and websites collectively, the “Site”) and the Services (as defined below). Before using our Services, please carefully read these Terms of Service (“Agreement”). This Agreement governs your use of the Services.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    1. Acceptance of Terms
                                </Text>

                                <Text

                                >
                                    By accessing and/or using the Services, you (“User” or “you”) acknowledge and agree to these legally binding Terms. You also agree to the Darkscreen Privacy Policy (“Privacy Policy”) and all other operational rules, policies, and procedures that may be published or otherwise made available on the Services, which are incorporated by reference.
                                </Text>

                                <Text

                                >
                                    You agree to use the Services only for lawful purposes, and that you are responsible for all activity in connection with your use of the Services and in your communications with us, all of which must comply with this Agreement. You hereby represent and warrant that you have the authority and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations and warranties set forth in this Agreement on behalf of yourself and/or an entity, and that you are able to abide by and comply with this Agreement. If you do not agree to the terms of this Agreement, then you do not have our permission to access or use the Site, App, or our Services.

                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    2. Darkscreen Services
                                </Text>

                                <Text
                                >
                                    Darkscreen provides services for providing tools enabling videographers to shoot, direct, and edit videos through the App and related video producing, directing, and editing tools, services, features, content, applications or products (together with the Site and App, the “Services”). Our Services may change from time to time and we reserve the right to modify, suspend, or discontinue the Services (including, but not limited to, the availability of any feature, integration, or Content), whether temporarily or permanently, at any time for any reason. You agree that Darkscreen shall not be liable to your or to any third party for any modification, suspension, or discontinuation of the Services. We may also impose limits on certain features and services or restrict your access to parts or all of the Services without notice or liability.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    3. Darkscreen Account Creation, Use, and Conduct
                                </Text>

                                <Text>Darkscreen Account Creation
                                </Text>

                                <Text>
                                    In order to use certain aspects of the Services, Users are required to have a Darkscreen account (“Account,” as further defined below) and provide certain information about such Account holder as prompted by the account registration form. You must be at least eighteen (18) years of age, or the age of majority in your applicable state, to register an account.
                                </Text>

                                <Text>
                                    You represent and warrant that the information in your Account, and any other information you otherwise provide to us, is accurate, current, and complete information, and agree to update it and keep it accurate, current, and complete. We reserve the right to suspend or terminate your Account or your access to the Services if any information provided to us proves to be untrue, inaccurate, not current, or incomplete. You are solely responsible for maintaining the confidentiality of your Account and log-in credentials, and you agree to accept responsibility for all activities, charges, and damages that occur under your Account. EACH INDIVIDUAL USER MUST MAINTAIN AN INDIVIDUAL USER ACCOUNT WITH UNIQUE LOG-IN CREDENTIALS, INCLUDING USERNAME AND PASSWORD, IF APPLICABLE.
                                </Text>

                                <Text>
                                    It shall be a violation of this Agreement to submit false information for registration or account maintenance, or to allow any other person to use your Account to participate in or otherwise use the Services. If you discover any unauthorized use of your Account, or other known Account-related security breach, you must report it to us immediately. You agree to accept responsibility for any and all activities or actions that occur under your Account and/or use of the Services. We cannot and will not be liable for any loss or damage arising from your failure to comply with this section.
                                </Text>
                                <Text>
                                    Prohibited Conduct
                                </Text>
                                <Text>
                                    As a condition of use, you agree not to use the Services for any purpose that is prohibited by the Terms or law. We reserve the right to modify, suspend or discontinue all or any aspect of the Services with or without notice to you.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    You understand and agree that you will not use the Services to engage in the following prohibited conduct:
                                </Text>

                                <ol type="1">
                                    <li>
                                        <Text>
                                            You shall not use the Services for any illegal or fraudulent purpose, or in violation of any
                                            local, state, national, or international law, including, without limitation, laws governing
                                            intellectual property and other proprietary rights, data protection and privacy, and import
                                            or export control;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not use the Services for purposes of competitive analysis, the development of a competing product or service, or any other purpose that is to our commercial disadvantage;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not submit information or documentation to the Site that pertains or belongs to any other party without such party’s prior written consent, nor knowingly submit any false or fraudulent information or documentation;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not use the Services to collect, use or disclose information that you do not have the consent to collect, use or disclose (including, but not limited to, the personally identifiable or confidential information of others, or information pertaining to minors under the age of 18 without parental consent);
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not post, store, send, transmit, or disseminate any information or material which infringes any patents, trademarks, trade secrets, copyrights, or any other proprietary or intellectual property rights;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>You shall not attempt to use any method to gain unauthorized access to any features of the Services;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not directly or indirectly decipher, decompile, remove, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Services, security-related features of the Services, features that prevent or restrict use or copying of any content accessible through the Services, or features that enforce limitations on use of the Services, except to the extent applicable laws specifically prohibit such restriction;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not directly or indirectly modify, translate, or otherwise create derivative works of any part of the Services;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not directly or indirectly license, copy, sell, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder or commercially exploit the Services, in whole or in part;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not directly or indirectly take any action that constitutes unsolicited or unauthorized advertising or promotional material or any junk mail, spam, or chain letters;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not directly or indirectly misrepresent your identity or suggest or otherwise create a false appearance of affiliation with Darkscreen or indicate that Darkscreen otherwise endorses, sponsors, or is affiliated with any products or services, nor impersonate any person or entity, including any employee or representative of Darkscreen;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You shall not directly or indirectly introduce into the Services any materials containing software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit, or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password, or other information of Darkscreen or any third party;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>You shall not directly or indirectly take any action that imposes or may impose (as determined by Darkscreen in its sole discretion) an unreasonable or disproportionately large load on Darkscreen’s or its third-party providers’ infrastructure; interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service; run any form of auto-responder or “spam” on the Service; or use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Site;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>You shall not sell or otherwise transfer your Account; and
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            You are prohibited from using the Services in a manner that: constitutes a direct or specific threat of violence to others; is in furtherance of illegal activities; is harassing, hateful, libelous, defamatory, abusive, vulgar, obscene, or constitutes spam; is pornographic, predatory, sexually graphic, racist, offensive, harmful to a minor, or would otherwise violate the rights of any third party or give rise to civil or criminal liability.
                                        </Text>
                                    </li>
                                </ol>
                                <Text>
                                    If for any reason, we determine that you have failed to follow these rules, we reserve the right to prohibit any and all current or future use of the Services by you. If we have reason to suspect, or learn that anyone is violating this Agreement, we may investigate and/or take legal action as necessary including bringing a lawsuit for damages caused by the violation. We reserve the right to investigate and take appropriate legal action, including without limitation, cooperating with and assisting law enforcement or government agencies in any resulting investigations of illegal conduct.
                                </Text>
                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    4. Additional User Responsibilities
                                </Text>
                                <Text>Acknowledgment of Platform</Text>
                                <Text>Darkscreen is not responsible for any loss or damage arising out of any decisions ultimately made or implemented based on your use of the Services. You understand and acknowledge that Darkscreen does not guarantee the accuracy, integrity, quality, safety, appropriateness or legality of any content or information made available on or through the Services, including but not limited to any User Content transmitted to or through the Services.
                                </Text>
                                <Text>
                                    Videographer Obligations
                                </Text>
                                <Text>
                                    Users who use the Services in order to request video clips from other parties or to manage the production of videos through the Services on behalf of yourself or an entity (an “Organization”) are referenced herein as Videographers. If you are using the Services as a Videographer, you additionally represent and warrant that you (a) are authorized to represent the Organization through your use of the Services and that you will obtain all applicable licenses, permits, permissions, authorizations, clearances and releases for any User Content that you create or request creation thereof through the Services (collectively, “Authorizations”) prior to the creation or submission of such User Content through the Services, and that you shall provide evidence of such Authorizations to us from time to time promptly upon our reasonable request; (b) will comply with all applicable laws, rules, or regulations related to your production or use of User Content through the Services, including but not limited to your compliance or enforcement of any and all applicable venue or location rules or identity verification of other Users.
                                </Text>
                                <Text>
                                    User Interactions with other Darkscreen Users; Dispute Resolution
                                </Text>
                                <Text>
                                    Subject to the terms of the Darkscreen Privacy Policy, your direct interactions (if any) with other Users of the Services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and that individual user. Like with any web-based interaction, we suggest that you use caution and good judgment. You further understand and agree that Darkscreen is not the agent or representative of any User for any purpose whatsoever and that it is not responsible for any loss or damage incurred as the result of any such dealings or with respect to any other User’s use or disclosure of your User Content or your personally identifiable information. For details about our information collection practices, please see our Privacy Policy. UNLESS OTHERWISE PROVIDED HEREIN, IF THERE IS A DISPUTE BETWEEN YOU AND ANY THIRD PARTY (INCLUDING, WITHOUT LIMITATION, ANY USER OF THE SERVICE), Darkscreen IS UNDER NO OBLIGATION TO BECOME INVOLVED, AND YOU HEREBY RELEASE Darkscreen FROM ANY CLAIMS, DEMANDS, OR DAMAGES OF ANY KIND AND OF ANY NATURE ARISING OUT OF OR RELATING TO ANY SUCH DISPUTE.
                                </Text>
                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    5. Availability of the Darkscreen Services
                                </Text>
                                <Text>While we use reasonable efforts to keep the Services accessible, the Services may be unavailable from time to time for any reason including, without limitation, system down time for routine maintenance. You further acknowledge that there may be interruptions in the Services or events on third-party sites that may affect your use of the Services that are beyond our control to prevent or correct. Accordingly, we cannot accept any responsibility for any connectivity issues that you may experience when using the Services or for any delays or loss of material, data, transactions or other information caused by system outages, whether planned or unplanned.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    6. Access/License for Use of the Services
                                </Text>

                                <Text>
                                    Darkscreen License to Users
                                </Text>

                                <Text>
                                    Subject to the terms of this Agreement, Darkscreen grants you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to use the Services and our Content strictly in accordance with this Agreement and all applicable laws, rules, and regulations. You agree to respect all legal or proprietary notices, information, and restrictions contained in any content accessed through the Services.
                                </Text>

                                <Text>
                                    License to Darkscreen to User Content
                                </Text>

                                <Text>
                                    In using the Services, you may be able to post, upload, or otherwise make available certain video clips, comments, instructions, information or documentation in order to use, or continue using, the Services (collectively, “User Content”). If you are using the Services on behalf of an Organization, you may be providing certain User Content that includes or incorporates the name, image, likeness, image, voice, appearance, intellectual property, or performance of another party (collectively, “Third-Party Materials”). Darkscreen does not claim ownership of any User Content. You understand and agree that you are responsible for whatever material you submit or collect through the Services and that you, not Darkscreen, have full responsibility for your User Content, including its legality, reliability, accuracy, appropriateness, originality, and copyright. By submitting User Content to the Services, you grant us and our service providers and business partners a nonexclusive, royalty-free, perpetual, irrevocable, sub-licensable, and transferable (in whole or in part) worldwide license to use, modify, display, reproduce, publish and distribute such User Content (as defined below) on and through the Service, including in connection with the development, production, distribution and/or exploitation (including marketing and promotion) of Darkscreen generally, unless otherwise prohibited by law. You agree that this license includes the right for us to make your User Content available to other users of the Services, subject to this Agreement and the Darkscreen Privacy Policy. We reserve the right to remove or refuse to post any User Content at any time in our sole discretion.
                                </Text>

                                <Text>
                                    User Content Warranties
                                </Text>

                                <Text>
                                    To the extent that you decide to post any User Content or Feedback on the Services or on Prompt Media social media pages, you represent and warrant to us that (a) you own the User Content, or you otherwise have the legal right to use it and you have received all necessary Authorizations from, or are authorized by, the owner of any part of such content (including but not limited to any Third-Party Materials) to submit it to the Services; (b) your User Content or Feedback will not contain third-party copyrighted material, or material that is subject to other third-party proprietary rights, unless you have permission from the rightful owner of the material or you are otherwise legally entitled to post the material and to grant us all of the license rights granted herein; and (c) you have no agreement with or obligations to any third party with respect to the rights herein granted which conflict or interfere with or adversely affect any of the provisions of this Agreement or the use or enjoyment by us of any of the rights herein granted.
                                </Text>

                                <Text>
                                    Enforcement; Validation of Content
                                </Text>

                                <Text>
                                    We, including our affiliates, are under no obligation to post or use any User Content you may provide. We may refuse to accept or transmit User Content. Additionally, we shall have the right to delete, edit, modify, reformat, excerpt, or translate any of your User Content for the purpose of providing Services to you. You understand and agree that Darkscreen is not and cannot be responsible for any User Content, including any information or materials therein, posted by Users on the Services, and that you must bear all risks associated with the exposure to and/or use of any such User Content. We reserve the right to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate any provision of this Agreement or otherwise create liability for us or any other person. Such action may include removing your User Content, terminating your Account in accordance with this Agreement, and/or reporting you to law enforcement authorities.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    7. Darkscreen Intellectual Property and Content
                                </Text>

                                <Text>
                                    Darkscreen Content
                                </Text>

                                <Text>
                                    Through the Services, we may make accessible various content, including, but not limited to, videos, photographs, images, artwork, graphics, audio clips, comments, data, text, software, scripts, campaigns, other material and information, and associated trademarks and copyrightable works (collectively, “Content”).
                                </Text>

                                <Text>
                                    Our Intellectual Property Rights and Content
                                </Text>

                                <Text>
                                    We own all rights, title and interest, including any patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how, and any other intellectual property or proprietary rights (collectively, “Intellectual Property”) in and to the Site, App and Services, or we are an authorized licensee for all Intellectual Property used for purposes of providing the Site, App and Services. All right, title, and interest in and to the Intellectual Property are and will remain with Darkscreen or its licensors. You will not use such Intellectual Property to develop competitive Services or sell, design, reverse engineer, decompile or disable any of the Services or software or change, translate, or otherwise create derivative works based off our Content. All other Content viewed through the Services is the property of its respective owner.
                                </Text>

                                <Text>
                                    We, including our affiliates, may ask you for your voluntary Feedback (as further defined below) on your experience with the Services. We shall become the owner of any User reviews, comments, suggestions or other feedback regarding the Services submitted through the Services or on Darkscreen's social media pages, if applicable (collectively, "Feedback") and we may share with any of our affiliates. Without limitation, we will have exclusive ownership of all present and future existing rights to the Feedback of every kind and nature everywhere and will be entitled to use the Feedback for any commercial or other purpose whatsoever, including to advertise and promote Darkscreen, without compensation to you or any other person sending the Feedback. You specifically waive any "moral rights" in and to the Feedback. You agree that any Feedback you submit to Darkscreen will not contain any information or ideas that you consider to be confidential or proprietary. ALL RIGHTS NOT EXPRESSLY GRANTED HEREUNDER ARE RESERVED TO Darkscreen.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    8. Payments
                                </Text>

                                <Text>
                                    In order to use certain features of the Services, you may be required to pay for the applicable subscription plan selected on the Site or App or via an order form for the Services (“Fees”). Darkscreen charges the Fees on a recurring basis at the selected time interval for your paid subscription plan to the payment method that you designate with Darkscreen through one of our third-party payment processors in accordance with our Privacy Policy. We are not responsible for the collection, use, sharing or security of your payment information by yours elected third-party payment processor. You hereby represent and warrant that you have the legal right to use the payment method(s) in connection with any purchase that you make on or through the Services.
                                </Text>

                                <Text>
                                    If your payment fails or if your payment information expires, you will be notified by Darkscreen and access to the Services will be suspended until payment is received. Darkscreen reserves the right to restrict access to your Account or terminate your Account for nonpayment if such nonpayment is not corrected within one week. If you dispute any charges, you must inform us in writing within one week of being billed by us. All payments are final and non-refundable.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    9. Term and Termination of Usage
                                </Text>

                                <Text>
                                    Subject to this section, this Agreement shall remain in full force and effect while you use the Services. You may terminate your Account in accordance with your selected subscription plan through your account settings on the Site or App or by sending us an email at support@darkscreen.co.
                                </Text>

                                <Text>
                                    If you are a paid subscriber of the Services, your paid subscription will be automatically renewed and the payment method on file with your Account will be automatically charged to your designated payment method for each new subscription period in your subscription plan unless you notify us in writing of your intent to cancel your paid subscription at least 30 days prior to your next subscription billing period.
                                </Text>

                                <Text>
                                    We may suspend or cancel your Account without notice to you if you violate this Agreement, or for any reason at all. If your Account is cancelled, we reserve the right to remove your account information along with any account settings from our servers with NO liability or notice to you. Once your account information and account settings are removed, you will not be able to recover this data from your Account.
                                </Text>

                                <Text>
                                    Upon termination of your Account, your license to use our Services terminates. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, and limitations of liability. You acknowledge and understand that our rights regarding any content you submitted to the website before your Account was terminated shall survive termination. For the avoidance of doubt, we may retain certain User Content in our backups, archives and disaster recovery systems in order to comply with applicable legal requirements or until such User Content is deleted in the ordinary course of business. Termination will not limit any of Darkscreen’s rights or remedies at law or equity.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    10. Advertisements and Third-Party Sites or Services
                                </Text>

                                <Text>
                                    The Services may contain links to other third-party websites or resources on the internet, or integrations with Darkscreen’s third-party service providers. Links on the Services to third party websites, if any, are provided only as a convenience to you and such links are not under the control of Darkscreen. If you use these links, you will leave the Services. The inclusion or integration of third-party services or links in the Services does not imply control of, endorsement by, or affiliation with Darkscreen. Your dealings with third parties are solely between you and such third parties. You agree that we will not be responsible or liable for any content, goods or services provided on or through these outside websites or integrated services or for your use or inability to use such websites or services. You will use these links or integrated services at your own risk.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    11. Copyright and Trademark Notices
                                </Text>

                                <Text>
                                    Copyright Policy
                                </Text>

                                <Text>
                                    Darkscreen complies with the Digital Millennium Copyright Act (DMCA). We will remove infringing materials in accordance with the DMCA if properly notified that Content infringes copyright. If you believe that your work has been copied in a way that constitutes copyright infringement, please notify the Darkscreen Copyright Agent by email at support@darkscreen.co or by mail to the address in the Contact section with the title ““Infringement of Intellectual Property Rights – DMCA” in such notice. Please do not send notices or inquiries about anything other than alleged copyright infringement or other intellectual property claims to our Agent for notice. Your email must contain the following information:
                                </Text>

                                <ul>
                                    <li>
                                        <Text>
                                            an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            information reasonably sufficient to permit us to contact you, such as your name, address, telephone number, and email address;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            a description of the copyrighted work that you claim has been infringed;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            a description of where the material that you claim is infringing is located on the Services, sufficient for us to locate the material;
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            a statement by you that the information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                                        </Text>
                                    </li>
                                </ul>

                                <Text>
                                    If you fail to comply with these notice requirements, your notification may not be valid. Under the Copyright Act, any person who knowingly materially misrepresents that material is infringing or was removed or disabled by mistake or misidentification may be subject to liability.
                                </Text>

                                <Text>
                                    In accordance with the Digital Millennium Copyright Act, we have adopted a policy of, in appropriate circumstances, terminating User accounts that are repeat infringers of the intellectual property rights of others. We may also terminate User accounts even based on a single infringement.
                                </Text>

                                <Text>
                                    Counter-Notice
                                </Text>

                                <Text>
                                    If you believe that your Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the material in your Content, you may send a written counter-notice containing the following information to the Copyright Agent: (1) Your physical or electronic signature; (2) Identification of the Content that has been removed or to which access has been disabled and the location at which the Content appeared before it was removed or disabled; (3) A statement that you have a good faith belief that the Content was removed or disabled as a result of mistake or a misidentification of the Content; and (4) Your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court in San Antonio, Texas, and a statement that you will accept service of process from the person who provided notification of the alleged infringement. If a counter-notice is received by the Copyright Agent, we may send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed Content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the Content provider, member or user, the removed Content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at our sole discretion.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    12. Warranty and Disclaimer
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    THE SERVICES AND ALL INFORMATION CONTAINED HEREIN ARE PROVIDED ON AN “AS IS” BASIS WITHOUT ANY WARRANTIES OF ANY KIND.
                                </Text>

                                <Text>
                                    Disclaimer of Actions of Other Users
                                </Text>

                                <Text>
                                    Darkscreen does not endorse and is not responsible or liable for any products, services, information, or materials available or unavailable from, or through, any third parties or other Users of the Service. You agree that should you use or rely on such products, services, information, or materials, Darkscreen is not responsible or liable, indirectly or directly, for any damage or loss caused or alleged to be caused by or in connection with such use or reliance. Your dealings with any other party or User, and any other terms, conditions, representations or warranties associated with such dealings, are between you and such party exclusively and do not involve Darkscreen. You should make whatever investigation or other resources that you deem necessary or appropriate before interacting with such other party. YOU WAIVE THE RIGHT TO BRING OR ASSERT ANY CLAIM AGAINST Darkscreen RELATING TO ANY INTERACTIONS OR DEALINGS WITH ANY USER OF THE SERVICE, AND RELEASE Darkscreen FROM ANY AND ALL LIABILITY FOR OR RELATING TO ANY INTERACTIONS OR DEALINGS WITH THIRD PARTIES OR OTHER USERS OF THE SERVICES.
                                </Text>

                                <Text>
                                    Disclaimer of Warranties
                                </Text>

                                <Text>
                                    THE Darkscreen SERVICES ARE PROVIDED TO USERS “AS-IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, Darkscreen AND ITS PARENTS, SUBSIDIARIES AND AFFILIATES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND PERTAINING TO THE SERVICES AND THE INFORMATION OR MATERIALS HEREIN, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, SECURITY, ACCURACY, AVAILABILITY, USE REASONABLE CARE AND SKILL, AND NON-INFRINGEMENT, AS WELL AS WARRANTIES ARISING BY USAGE OF TRADE, COURSE OF DEALING, AND COURSE OF PERFORMANCE. ALL INFORMATION PROVIDED ON OR THROUGH THE SERVICES IS SUBJECT TO CHANGE WITHOUT NOTICE. Darkscreen MAKES NO WARRANTY THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS OR THAT INFORMATION MADE AVAILABLE THROUGH THE SERVICES IS ACCURATE OR COMPLETE, (II) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE OR FREE OF VIRUSES OR BUGS, AND (III) ANY ERRORS IN OR ON THE SERVICES WILL BE CORRECTED. ANY MATERIAL, CONTENT, OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED AND/OR USED THROUGH THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL, CONTENT OR INFORMATION. FURTHER, Darkscreen SHALL NOT BE LIABLE FOR ANY DECISION MADE OR IMPLEMENTED BASED ON USER’S USE OF THE SERVICES. YOU AGREE THAT USE OF THE SERVICES IS AT YOUR OWN RISK, AND Darkscreen ASSUMES NO RESPONSIBILITY OR LIABILITY FOR ANY INFORMATION COLLECTED BY YOUR USE OF THESERVICES, OR THE TRUTHFULNESS, ACCURACY, TIMELINESS, OR COMPLETENESS OF ANY CONTENT OR FAILURE BY THE SERVICES.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    13. Limitation of Liability
                                </Text>

                                <Text>
                                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE Darkscreen, TOGETHER WITH ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, PARTNERS, SUPPLIERS, CONTRACTORS, OR CONTENT PROVIDERS, FROM ALL LIABILITY ASSOCIATED WITH YOUR USE OF THE SERVICES. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
                                </Text>

                                <Text>
                                    EXCEPT AS OTHERWISE SPECIFICALLY PROVIDED AND TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL Darkscreen, NOR ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, PARTNERS, SUPPLIERS, CONTRACTORS, OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF THE AMOUNT OF FEES PAID AND OWED BY YOU TO Darkscreen UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE DATE THE CLAIM AROSE. THE LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                                </Text>

                                <Text>
                                    SOME STATES OR COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. IN THESE JURISDICTIONS, Darkscreen’S LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    14. Indemnification
                                </Text>

                                <Text>
                                    You agree to indemnify, defend, and hold harmless Darkscreen (including our affiliates and subsidiaries, as well as our and their respective officers, directors, employees, agents, partners, suppliers, contractors, content providers, successors, and assigns) from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees, arising from or relating to your use or misuse of the Services or your breach of this Agreement, including but not limited to your breach of any law or the rights of a third party.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    15. Arbitration and Class Action Waiver
                                </Text>
                                <Text>
                                    PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT
                                </Text>

                                <Text>
                                    Initial Dispute Resolution
                                </Text>

                                <Text>
                                    For any problem or dispute that you may have with us, you acknowledge and agree that you will first give Darkscreen an opportunity to resolve your problem or dispute. In order to initiate this dispute resolution process, you must first send us a written description of your problem or dispute within thirty (30) days of the occurrence of the event giving rise to the dispute by sending an email to support@darkscreen.co. You then agree to negotiate with us in good faith about the dispute for at least sixty (60) days after our receipt of your written description of it.
                                </Text>

                                <Text

                                >
                                    Binding Arbitration
                                </Text>

                                <Text>
                                    If the parties do not reach an agreed upon solution within a period of 30 days from the time informal dispute resolution under the Initial Dispute Resolution provision, then either party may initiate binding arbitration as the sole means to resolve claims, subject to the terms set forth below. Specifically, all claims arising out of or relating to this Agreement (including their formation, performance and breach), the parties’ relationship with each other and/or your use of the Service shall be finally settled by binding arbitration administered by the American Arbitration Association in accordance with the provisions of its Commercial Arbitration Rules and the supplementary procedures for consumer related disputes of the American Arbitration Association (the “AAA”), excluding any rules or procedures governing or permitting class actions. The Commercial Arbitration Rules governing the arbitration may be accessed at www.adr.org or by calling the AAA at +1.800.778.7879.
                                </Text>

                                <Text>
                                    The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of this Agreement, including, but not limited to any claim that all or any part of this Agreement is void or voidable, or whether a claim is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitration rules also permit you to recover attorney’s fees in certain cases. The arbitrator’s award shall be written, and binding on the parties and may be entered as a judgment in any court of competent jurisdiction.
                                </Text>

                                <Text>
                                    The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court.
                                </Text>

                                <Text>
                                    Location
                                </Text>

                                <Text>
                                    The arbitration will take place in San Antonio, Texas.
                                </Text>

                                <Text>
                                    Class Action Waiver
                                </Text>

                                <Text>
                                    The parties further agree that any arbitration shall be conducted in their individual capacities only and not as a class action or other representative action, and the parties expressly waive their right to file a class action or seek relief on a class basis. YOU AND Darkscreen AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision set forth above shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.
                                </Text>

                                <Text>
                                    Exception – Litigation of Intellectual Property and Small Claims Court Claims
                                </Text>

                                <Text>
                                    Notwithstanding the parties’ decision to resolve all disputes through arbitration, either party may bring an action in state or federal court to protect its intellectual property rights (“intellectual property rights” means patents, copyrights, moral rights, trademarks, and trade secrets, but not privacy or publicity rights). Either party may also seek relief in a small claims court for disputes or claims within the scope of that court’s jurisdiction.
                                </Text>

                                <Text>
                                    30-Day Right to Opt-Out
                                </Text>

                                <Text>
                                    You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth above by sending written notice of your decision to opt-out to us at support@darkscreen.co. The notice must be sent within 30 days of your first use of the Service, otherwise you shall be bound to arbitrate disputes in accordance with the terms of those paragraphs. If you opt-out of these arbitration provisions, we also will not be bound by them.
                                </Text>

                                <Text>
                                    Changes to this Section
                                </Text>

                                <Text>
                                    We will provide 30-days’ notice of any changes to this section. Changes will become effective on the 30th day, and will apply prospectively only to any claims arising after the 30th day.
                                </Text>

                                <Text>
                                    The Terms and the relationship between you and Darkscreen shall be governed by the laws of the State of Texas without regard to conflict of law provisions
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    16. Assignment
                                </Text>

                                <Text>
                                    This Agreement is personal to User and you may not assign, transfer, sublicense, subcontract, charge or otherwise encumber any of your rights or obligations under this Agreement without the prior written consent of Darkscreen. Any assignment in violation of this section shall be null and void. Darkscreen may assign, transfer, or delegate any of its rights and obligations hereunder without your consent.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    17. No Third-Party Beneficiaries
                                </Text>

                                <Text>
                                    The parties agree that except as otherwise expressly provided in this Agreement, there shall be no third-party beneficiaries to the Terms.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    18. Notice Policy and Your Consent
                                </Text>

                                <Text>
                                    Under this Agreement you are contracting with Darkscreen Inc., a Texas limited liability company. All notices should be addressed to us at the address in the Contact section below.
                                </Text>

                                <Text>
                                    You acknowledge and agree that we may give you notice by means of a general notice on the Services, electronic mail to your email address in your account, text message, or by written communication sent by first class mail or pre-paid post to your address in your account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email or text). You may give notice to us, with such notice deemed given when received by us, at any time by first class mail or pre-paid post to the address set forth in the Contact section or at such other address as we may advise from time to time, pursuant to this provision.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    19. Governing Law
                                </Text>

                                <Text>
                                    This Agreement (and any further rules, policies, or guidelines incorporated by reference) shall be governed by and construed in accordance with the laws of the State of Texas and the United States, without giving effect to any principles of conflicts of law, and without application of the Uniform Computer Information Transaction Act or the United Nations Convention of Controls for International Sale of Goods.
                                </Text>

                                <Text>
                                    You agree that Darkscreen and its Services are deemed passive and do not give rise to personal jurisdiction over Darkscreen or its parents, subsidiaries, affiliates, successors, assigns, employees, agents, directors, officers or shareholders, either specific or general, in any jurisdiction other than the State of Texas. You agree that any action at law or in equity arising out of or relating to this Agreement, or your use or non-use of the Services, shall be filed only in the state or federal courts located in Bexar County in the State of Texas and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action. You irrevocably waive any right you may have to trial by jury in any dispute, action, or proceeding.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    20. Entire Agreement and Severability
                                </Text>

                                <Text>
                                    This Agreement, and all terms and policies posted through our Services, including our Privacy Policy and any applicable Supplemental Terms, constitutes the entire agreement between you and us with respect to the Services, and supersedes all prior or contemporaneous agreements, representations, warranties, and understandings (whether oral, written or electronic) between you and us with respect to the Services. If a court in any final, unappealable proceeding holds any provision of this Agreement or its application to any person or circumstance invalid, illegal or unenforceable, the remainder of this Agreement shall not be affected and shall be valid, legal, and enforceable to the fullest extent permitted by law.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    21. Modification of Terms
                                </Text>

                                <Text>
                                    We reserve the right, at our sole discretion, to amend, modify, or replace this Agreement, including the Privacy Policy or any Supplemental Terms, from time to time. If changes to this Agreement, Supplemental Terms or Privacy Policy occur, we will notify you by posting the updated terms on the Site, or by email to the email affiliated with your account. Your continued use of the Services following the posting of such changes means you acknowledge and accept those changes and agree to be bound by the new terms and conditions. If you object to such changes, you must stop using the Services.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    22. No Waiver
                                </Text>

                                <Text>
                                    No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    23. Relationship of the Parties
                                </Text>

                                <Text>
                                    Becoming a User of our Services does not create an agency, employment, joint venture, franchise or partnership relationship between you and Darkscreen.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    24. Supplemental Terms
                                </Text>

                                <Text>
                                    Your use of the Services may be subject to additional terms and conditions, such as a set of terms that apply to a specific Darkscreen product or feature on the Services, which may be modified from time to time (“Supplemental Terms”). Supplemental Terms are in addition to, and shall be deemed a part of, this Agreement. In the event of a conflict between the Supplemental Terms and this Agreement, the Supplemental Terms shall prevail solely to the extent of such conflict.
                                </Text>

                                <Text
                                    color="black"
                                    fontWeight={700}
                                >
                                    25. Contact
                                </Text>

                                <Text>
                                    If you have any questions regarding this Agreement, please contact us at support@darkscreen.co or by mail at:
                                </Text>


                                <Flex
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignContent="flex-start"
                                    alignItems="flex-start"
                                    gap="0rem"
                                >

                                    <Text
                                        as="p"
                                    >
                                        Darkscreen Inc. DBA Darkscreen
                                    </Text>
                                    <Text
                                        as="p"
                                    >
                                        Attn: Darkscreen Support
                                    </Text>
                                    <Text
                                        as="p"
                                    >
                                        701 Brazos St
                                    </Text>
                                    <Text>
                                        Austin, TX 78701
                                    </Text>
                                </Flex>

                                <Text>
                                    I HEREBY ACKNOWLEDGE THAT I HAVE READ AND UNDERSTAND THIS AGREEMENT AND THE PRIVACY POLICY, AND AGREE THAT MY USE OF THE SERVICES IS AN ACKNOWLEDGMENT OF MY AGREEMENT TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                                </Text>

                            </Flex>

                        </Flex>

                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Flex>

    )


}
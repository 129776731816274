import React, {useEffect, useState} from 'react';
import {Autocomplete, Button, ComboBoxOption, Flex, HighlightMatch, Icon, Link, Text} from '@aws-amplify/ui-react';
import {HeaderNavigation} from "../components/HeaderNavigation";
import {Footer} from "../components/Footer";
import {
    AUser
} from "../API";
import {useLocation, useNavigate} from "react-router-dom";
import useWindowDimensions from "../helpers/WindowHelpers";
import {PageLoadingState} from "../helpers/PageLoadingState";
import "@fontsource/dm-sans";
import {RiAppleFill, RiGooglePlayFill} from "react-icons/ri";
import {Header} from "../components/Header";

export const HomePage: React.FC = () => {

    const [user, setUser] = useState<AUser | undefined>(undefined);
    const [pageLoadingState, setPageLoadingState] = useState(PageLoadingState.NONE);
    const navigate = useNavigate();
    const location = useLocation();
    const { height, width } = useWindowDimensions();


    useEffect(() => {
        setupPageData()
    }, [user]);

    const setupPageData = async () => {
        if(pageLoadingState == PageLoadingState.NONE) {
            setPageLoadingState(PageLoadingState.LOADING);
            setPageLoadingState(PageLoadingState.COMPLETED);
        }

    }


    return (
        <Flex direction="column" gap="0rem" height="100vh" width="100%" backgroundColor="black"
              justifyContent="center"
              alignItems="center" alignContent="center"
        >
            <HeaderNavigation />
            <Flex direction="column" justifyContent="center"
                  alignItems="center" alignContent="center"
                  gap="2rem" width="100%" height="100%"
                  paddingLeft="1rem" paddingRight="1rem"
                  maxWidth="500px"
            >

                <Text
                    color="white"
                    fontFamily="DM Sans"
                    fontWeight={200}
                    fontSize="5.0rem"
                    letterSpacing="0.5rem"
                    textTransform="uppercase"
                >
                    Darkscreen Annotate is...
                </Text>

                <Text
                    color="white"
                    fontFamily="DM Sans"
                    fontWeight={300}
                    fontSize="1.5rem"
                    textAlign="center"
                >
                    Darkscreen Annotate is a platform that allows you to create interactive marketing and advertising campaigns in AR, MR, and VR!
                </Text>

                <Flex
                    direction="row" justifyContent="center"
                    alignItems="center" alignContent="center"
                    gap="4rem"
                >
                    <Link
                        isDisabled={true}
                    >
                        <Icon
                            color="gray"
                            width="40px"
                            height="40px"
                            as={RiAppleFill}

                        />

                    </Link>

                    <Link
                        isDisabled={true}
                    >
                        <Icon
                            color="gray"
                            width="40px"
                            height="40px"
                            as={RiGooglePlayFill}

                        />

                    </Link>

                </Flex>


            </Flex>

            <Footer/>
        </Flex>
    );
}
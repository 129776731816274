import React, { useState, useEffect, useReducer } from 'react';
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import {useAuthenticator, View, Button, PhoneNumberField, Flex, Text} from '@aws-amplify/ui-react';
import {PageLoadingState} from "../helpers/PageLoadingState";
import useWindowDimensions from "../helpers/WindowHelpers";

export const ErrorPage: React.FC = () => {

    // const [amplifyUser, setAmplifyUser] = useState<AmplifyUser | undefined>(undefined);
    // const [user, setUser] = useState<User | undefined>(undefined);
    const {height, width} = useWindowDimensions();
    const navigate = useNavigate();
    const location = useLocation();
    const [pageLoadingState, setPageLoadingState] = useState(PageLoadingState.LOADING);

    useEffect(() => {
        setupPageData()
    }, []);

    const setupPageData = async () => {
        setPageLoadingState(PageLoadingState.LOADING);

        setPageLoadingState(PageLoadingState.COMPLETED);
    }


    return (
        <div className="errorPageContainer">
            <Flex direction="column" alignContent="center" alignItems="center" justifyContent="flex-start" minHeight="calc(100vh-156px)" gap="0rem">
                {/*<HeaderNavigation amplifyUser={amplifyUser} user={user}/>*/}
            <Header/>
                <Flex alignContent="center" alignItems="center" justifyContent="flex-start" direction="column" gap="1rem" maxWidth="450px" paddingTop="2rem">
                    <Text
                        color="black"
                        fontSize="2em"
                        fontWeight="700"
                        lineHeight="1.0em"
                    >
                        Something Went Wrong!
                    </Text>

                    <Text
                        color="gray"
                        fontSize="1rem"
                        fontWeight="250"
                        lineHeight="1.5em"
                    >
                        Please try again. If this issue continues, please report it on the support page or on our social media platforms.
                    </Text>

                </Flex>
                <Footer />
            </Flex>
        </div>
    );
}
import { Divider, Link, Flex, Text, useTheme } from "@aws-amplify/ui-react";
import {PageLoadingState} from "../helpers/PageLoadingState";

export interface FooterProps {
    backgroundColor?: string
}



export function Footer(props: FooterProps)  {
    const { tokens } = useTheme();
    const overallStyle = {
        borderTop: '1px solid rgb(12, 11, 11)'
    };

    return (
        <Flex direction="column"
              width="100%"
              justifyContent="center"
              paddingTop={tokens.space.medium} paddingBottom={tokens.space.medium}
              backgroundColor={props.backgroundColor ?? "black"}
              style={overallStyle}
        >
            <Flex justifyContent="center"

            >

                <Link href="/src/pages/Privacy" color="gray">
                    Privacy
                </Link>
                <Link href="/src/pages/Terms" color="gray">
                    Terms
                </Link>
                <Link href="/community-standards" color="gray">
                    Community Standards
                </Link>
                <Link href="/help" color="gray">
                    Help
                </Link>
            </Flex>
            <Flex justifyContent="center">
                <Text
                    color="gray"
                >Darkscreen Inc. &copy; 2023-2024 All Rights Reserved </Text>
            </Flex>
        </Flex>
    );
}
import React, { useState, useRef, useEffect, useReducer } from 'react';
import { Amplify, } from 'aws-amplify';
import {
    useAuthenticator,
    View,
    Button,
    Text,
    PhoneNumberField,
    TextField,
    Card,
    Flex,
    Menu,
    MenuItem,
    Divider,
    Tabs,
    Link,
    Grid, Image,
    SelectField, Icon, MenuButton, ComboBoxOption
} from '@aws-amplify/ui-react';

import {AUser, ListAUsersQuery } from "../API";
import {useLocation, useNavigate, Link as ReactLink} from "react-router-dom";
import useWindowDimensions from "../helpers/WindowHelpers";
import {PageLoadingState} from "../helpers/PageLoadingState";
 // import {AmplifyUser} from "@aws-amplify/ui/dist/types/types/authenticator/user";
import logo_gradient_horz from "../resources/images/ds-logo-gradient-horz.svg";


export interface HeaderNavigationProps {

    backgroundColor?: string

    pageLoadingState?: PageLoadingState
    // amplifyUser?: AmplifyUser;
    user?: AUser
}

export interface HeaderNavigationState {

}

const DEFAULT_STATE: HeaderNavigationState = {

}

export function HeaderNavigationMobile(props: HeaderNavigationProps) {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setupPageData()
    }, []);

    const setupPageData = async () => {

    }

    return (
        <Flex
            direction="row"
            alignContent="center" alignItems="center" justifyContent="space-between" width="100vw" paddingLeft="1rem" paddingRight="1rem"
            backgroundColor={props.backgroundColor ?? "black"}

        >
            <Link
                href="/brands/promptvideo"
                color="black"
            >
                Get help
            </Link>

            <Link href="/"  color="white">
                <Flex alignContent="center" alignItems="center" justifyContent="center" padding="1rem">

                    <Image
                        alt="logo"
                        src={logo_gradient_horz}
                        maxHeight="35px"
                        // src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                    />
                </Flex>
            </Link>


            {/*{ props.user  ?*/}

            {/*    // <Menu*/}
            {/*    //     width="296px"*/}
            {/*    //     menuAlign="end"*/}
            {/*    //     trigger={*/}
            {/*    //         <MenuButton*/}
            {/*    //             className="menu-account"*/}
            {/*    //             variation="link" padding="0rem" style={{outline: "none"}}>*/}
            {/*    //             /!*{props.user?.picture == undefined ? "Account" : "Account"  />}*!/*/}
            {/*    //         </MenuButton>*/}
            {/*    //     }*/}
            {/*    // >*/}
            {/*    //     <Flex direction="row"*/}
            {/*    //           width="100%"*/}
            {/*    //           padding="1rem"*/}
            {/*    //     >*/}
            {/*    //         /!*<UserPicturePreview user={props.user} size="64px" />*!/*/}
            {/*    //         <Flex direction="column"*/}
            {/*    //               gap="0rem"*/}
            {/*    //               justifyContent="center"*/}
            {/*    //               alignContent="flex-start"*/}
            {/*    //               alignItems="flex-start"*/}
            {/*    //         >*/}
            {/*    //             <Text*/}
            {/*    //                 color="black"*/}
            {/*    //                 fontSize="1rem"*/}
            {/*    //                 fontWeight={650}*/}
            {/*    //             >*/}
            {/*    //                 {props.user?.name ?? "No Name"}*/}
            {/*    //             </Text>*/}
            {/*    //             <Text*/}
            {/*    //                 color="gray"*/}
            {/*    //                 fontSize="0.75rem"*/}
            {/*    //                 fontWeight={400}*/}
            {/*    //             >*/}
            {/*    //                 {props.user?.number ?? props.user?.email ?? "unknown"}*/}
            {/*    //             </Text>*/}
            {/*    //         </Flex>*/}
            {/*    //*/}
            {/*    //     </Flex>*/}
            {/*    //     <Divider border="1px solid lightgray" />*/}
            {/*    //     <MenuItem onClick={() => navigate("/account")}>*/}
            {/*    //         <Text*/}
            {/*    //             fontSize="0.8rem"*/}
            {/*    //             fontWeight={400}*/}
            {/*    //             paddingLeft="0.5rem"*/}
            {/*    //         >*/}
            {/*    //             My Account*/}
            {/*    //         </Text>*/}
            {/*    //     </MenuItem>*/}
            {/*    //     <MenuItem onClick={() => navigate("/account/brands")}>*/}
            {/*    //         <Text*/}
            {/*    //             fontSize="0.8rem"*/}
            {/*    //             fontWeight={400}*/}
            {/*    //             paddingLeft="0.5rem"*/}
            {/*    //         >*/}
            {/*    //             My Brands*/}
            {/*    //         </Text>*/}
            {/*    //     </MenuItem>*/}
            {/*    //     /!*<MenuItem onClick={() => navigate("/account/settings")}>*!/*/}
            {/*    //     /!*    <Text*!/*/}
            {/*    //     /!*        fontSize="0.8rem"*!/*/}
            {/*    //     /!*        fontWeight={400}*!/*/}
            {/*    //     /!*        paddingLeft="0.5rem"*!/*/}
            {/*    //     /!*    >*!/*/}
            {/*    //     /!*        Account Settings*!/*/}
            {/*    //     /!*    </Text>*!/*/}
            {/*    //     /!*</MenuItem>*!/*/}
            {/*    //     <Divider border="1px solid lightgray" />*/}
            {/*    //     <MenuItem onClick={() => navigate("/brands/promptvideo")}>*/}
            {/*    //         <Text*/}
            {/*    //             fontSize="0.8rem"*/}
            {/*    //             fontWeight={400}*/}
            {/*    //             paddingLeft="0.5rem"*/}
            {/*    //         >*/}
            {/*    //             Get help*/}
            {/*    //         </Text>*/}
            {/*    //     </MenuItem>*/}
            {/*    //     <MenuItem onClick={() => navigate("/terms")}>*/}
            {/*    //         <Text*/}
            {/*    //             fontSize="0.8rem"*/}
            {/*    //             fontWeight={400}*/}
            {/*    //             paddingLeft="0.5rem"*/}
            {/*    //         >*/}
            {/*    //             Terms of Service*/}
            {/*    //         </Text>*/}
            {/*    //     </MenuItem>*/}
            {/*    //     <MenuItem onClick={() => {*/}
            {/*    //         props.amplifyUser?.signOut();*/}
            {/*    //         navigate("/");*/}
            {/*    //         navigate(0);*/}
            {/*    //     }}>*/}
            {/*    //         <Text*/}
            {/*    //             fontSize="0.8rem"*/}
            {/*    //             fontWeight={400}*/}
            {/*    //             paddingLeft="0.5rem"*/}
            {/*    //         >*/}
            {/*    //             Sign out*/}
            {/*    //         </Text>*/}
            {/*    //     </MenuItem>*/}
            {/*    // </Menu>*/}

            {/*:*/}

            {/*    <Menu*/}
            {/*        menuAlign="end"*/}
            {/*    >*/}
            {/*        <MenuItem onClick={() => navigate("/signin", {state: {"redirectUrl": `${location.pathname}`}})}>*/}
            {/*            Sign in*/}
            {/*        </MenuItem>*/}
            {/*        <MenuItem onClick={() => navigate("/signin")}>*/}
            {/*            Create Account*/}
            {/*        </MenuItem>*/}
            {/*    </Menu>*/}

            {/*}*/}


        </Flex>
    );
}

export function HeaderNavigationDesktop(props: HeaderNavigationProps) {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setupPageData()
    }, []);

    const setupPageData = async () => {

    }

    return (
        <Flex
            direction="row"
            alignContent="flex-star" alignItems="center" justifyContent="space-between"
            width="100%"
            maxWidth="1000px"
            height="54px"
            padding="0 32px"
        >

            <Flex
                direction="row" alignContent="center" alignItems="center" justifyContent="flex-start"
                height="60px" maxWidth="980px" padding="0 22px" width="100%"

            >
                <Link href="/" color="white">
                    <Flex alignContent={"center"} alignItems={"center"} justifyContent={"center"} padding="0rem"
                        gap="0.1rem"
                    >

                        <Image
                            alt="logo"
                            src={logo_gradient_horz}
                            height="40px"
                            // src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                        />

                        <Text
                            color="white"
                            fontWeight={800}
                            >
                            darkscreen
                        </Text>
                    </Flex>
                </Link>

                <Flex direction="row" justifyContent="space-between" alignItems="center"
                      alignContent="flex-start" height="52px"
                      padding="0 22px" gap="2rem"
                >
                    <Link href="/" color="lightgray">
                        Annotate
                    </Link>

                    <Link href="/" color="lightgray">
                        Support
                    </Link>

                    <Link href="/" color="lightgray">
                        Account
                    </Link>
                </Flex>


            </Flex>

            <Flex
                direction="row" alignContent="center" alignItems="center" justifyContent="center" height="100%"
                gap="0.75rem"

            >

                { props.user ?
                    <Flex
                        direction="row" alignContent="center" alignItems="center" justifyContent="center" height="100%"
                        gap="0.75rem"

                    >
                        {/*<Button*/}
                        {/*    variation="link"*/}
                        {/*    color="black"*/}
                        {/*    fontSize="0.9rem"*/}
                        {/*    fontWeight={500}*/}
                        {/*    textAlign="center"*/}
                        {/*    padding="8px"*/}
                        {/*    borderRadius="5px"*/}
                        {/*    onClick={ ()=> {*/}
                        {/*        props.user?.signOut()*/}
                        {/*        navigate("/");*/}
                        {/*        navigate(0);*/}
                        {/*    }}*/}

                        {/*>*/}
                        {/*    Sign Out*/}
                        {/*</Button>*/}

                        <Menu
                            width="296px"
                            menuAlign="end"
                            trigger={
                                <MenuButton
                                    className="menu-account"
                                    variation="link" padding="0rem" style={{outline: "none"}}>
                                    {/*{props.user?.picture == undefined ? "Account" : <UserPicturePreview user={props.user} size="40px" />}*/}
                                </MenuButton>
                            }
                        >
                            <Flex direction="row"
                                  width="100%"
                                  padding="1rem"
                            >
                                {/*<UserPicturePreview user={props.user} size="64px" />*/}
                                <Flex direction="column"
                                      gap="0rem"
                                      justifyContent="center"
                                      alignContent="flex-start"
                                      alignItems="flex-start"
                                >
                                    <Text
                                        color="black"
                                        fontSize="1rem"
                                        fontWeight={650}
                                    >
                                        {props.user?.name ?? "No Name"}
                                    </Text>
                                    <Text
                                        color="gray"
                                        fontSize="0.75rem"
                                        fontWeight={400}
                                    >
                                        {props.user?.number ?? props.user?.email ?? "unknown"}
                                    </Text>
                                </Flex>

                            </Flex>
                            <Divider border="1px solid lightgray" />
                            <MenuItem onClick={() => navigate("/account")}>
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight={400}
                                    paddingLeft="0.5rem"
                                >
                                    My Account
                                </Text>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/account/brands")}>
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight={400}
                                    paddingLeft="0.5rem"
                                >
                                    My Brands
                                </Text>
                            </MenuItem>
                            {/*<MenuItem onClick={() => navigate("/account/settings")}>*/}
                            {/*    <Text*/}
                            {/*        fontSize="0.8rem"*/}
                            {/*        fontWeight={400}*/}
                            {/*        paddingLeft="0.5rem"*/}
                            {/*    >*/}
                            {/*        Account Settings*/}
                            {/*    </Text>*/}
                            {/*</MenuItem>*/}
                            <Divider border="1px solid lightgray" />
                            <MenuItem onClick={() => navigate("/brands/promptvideo")}>
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight={400}
                                    paddingLeft="0.5rem"
                                >
                                    Get help
                                </Text>
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/terms")}>
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight={400}
                                    paddingLeft="0.5rem"
                                >
                                    Terms of Service
                                </Text>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                // props.amplifyUser?.signOut();
                                navigate("/");
                                navigate(0);
                            }}>
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight={400}
                                    paddingLeft="0.5rem"
                                >
                                    Sign out
                                </Text>
                            </MenuItem>
                        </Menu>
                    </Flex>



                    :
                    <Flex
                        direction="row" alignContent="center" alignItems="center" justifyContent="center" height="100%"
                        gap="0.75rem"

                    >

                        {/*<ReactLink*/}
                        {/*    to="/signin"*/}
                        {/*    state={{"redirectUrl": location.pathname}}*/}
                        {/*    >*/}
                        {/*    <Link*/}
                        {/*        color="white"*/}
                        {/*        fontSize="0.9rem"*/}
                        {/*        fontWeight={500}*/}
                        {/*        width="70px"*/}
                        {/*        textAlign="center"*/}
                        {/*        padding="8px"*/}
                        {/*        borderRadius="5px"*/}
                        {/*    >*/}
                        {/*        Sign In*/}
                        {/*    </Link>*/}
                        {/*</ReactLink>*/}

                        {/*<Link*/}
                        {/*    href="/signin"*/}
                        {/*    backgroundColor="teal"*/}
                        {/*    color="white"*/}
                        {/*    fontSize="0.9rem"*/}
                        {/*    fontWeight={500}*/}
                        {/*    textAlign="center"*/}
                        {/*    padding="8px"*/}
                        {/*    borderRadius="5px"*/}
                        {/*>*/}
                        {/*    Create Free Account*/}
                        {/*</Link>*/}
                    </Flex>
                }



            </Flex>

        </Flex>
    );
}

export function HeaderNavigation(props: HeaderNavigationProps) {

    const { height, width } = useWindowDimensions();
    const overallStyle = {
        borderBottom: '1px solid rgb(12, 11, 11)'
    };

    return (
        <Flex
            direction="row"
            alignContent="center" alignItems="center" justifyContent="center" style={overallStyle}
            width="100%"
        >
            { width > 750 ? HeaderNavigationDesktop(props) : HeaderNavigationMobile(props) }
        </Flex>
    );
}
import {Flex, Link, Image, useTheme, useAuthenticator, Menu, MenuItem, Button} from "@aws-amplify/ui-react";
import logo from '../resources/images/pv-logo-black-horz.png'
import useWindowDimensions from "../helpers/WindowHelpers";
import React, {useEffect, useState} from "react";
import {PageLoadingState} from "../helpers/PageLoadingState";
import {useLocation, useNavigate} from "react-router-dom";
import logo_gradient_horz from "../resources/images/ds-logo-gradient-horz.svg";
import {HeaderNavigationProps} from "./HeaderNavigation";

export function HeaderMobile(props: HeaderNavigationProps) {

    const { height, width } = useWindowDimensions();
    const [ pageLoadingState, setPageLoadingState] = useState(PageLoadingState.NONE);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Flex
            direction="row"
            alignContent="center" alignItems="center" justifyContent="center" width="100vw" paddingLeft="1rem" paddingRight="1rem"
        >
            <Link href="/" color="white">
                <Flex alignContent="center" alignItems="center" justifyContent="center" padding="1rem">

                    <Image
                        alt="logo"
                        src={logo_gradient_horz}
                        maxHeight="35px"
                        // src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                    />
                </Flex>
            </Link>

        </Flex>
    );
}

export function HeaderDesktop(props: HeaderNavigationProps) {

    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();

    return (
        <Flex
            direction="row"
            alignContent="flex-start" alignItems="center" justifyContent="flex-start"
            backgroundColor="black"
            width="100%"
            height="44px"
            padding="0 60px"
        >

            <Flex
                direction="row" alignContent="flex-start" alignItems="center" justifyContent="flex-start"
                height="60px"

            >
                <Link href="/" color="white">
                    <Flex alignContent={"center"} alignItems={"center"} justifyContent={"center"} padding="0rem">

                        {/*<Image*/}
                        {/*    alt="logo"*/}
                        {/*    src={logo_black_horz}*/}
                        {/*    height="40px"*/}
                        {/*    // src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"*/}
                        {/*/>*/}
                    </Flex>
                </Link>

                <Flex direction="row" justifyContent="center" alignItems="center" alignContent="flex-start" height="52px">

                </Flex>


            </Flex>

            <Flex
                direction="row" alignContent="center" alignItems="center" justifyContent="center" height="100%"
                gap="0.75rem"

            >


            </Flex>

        </Flex>
    );
}

export function Header(props: HeaderNavigationProps) {

    const { height, width } = useWindowDimensions();
    const overallStyle = {
        borderBottom: '1px solid rgb(12, 11, 11)'
    };

    return (
        <Flex
            direction="row"
            width="100%"
            alignContent="center" alignItems="center" justifyContent="center" style={overallStyle}
        >
            { width > 1000 ? HeaderDesktop(props) : HeaderMobile(props) }
        </Flex>
    );
}